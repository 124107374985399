<template>
	<v-card :loading="loadingParent" max-width="800" class="mx-auto" rounded="xl">
		<v-card-title>
			<span>{{ $t('offers.aboutTheOffer') }}</span>
		</v-card-title>
		<v-card-text>
			<p><v-icon>mdi-calendar</v-icon> {{ offer.info.startingDate }}</p>
			<p><v-icon>mdi-clock-outline</v-icon> {{ offer.info.duration }}</p>
			<p>
				<v-icon>mdi-map-marker</v-icon> {{ offer.info.location.city }}
				<template v-if="offer.info.location.country.key == 'ES'"> - {{ offer.info.location.province }} </template>
				({{ offer.info.location.country.name }})
			</p>
			<p style="white-space: pre-line">
				{{ offer.info.description }}
			</p>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'OfferAbout',
	props: {
		loadingParent: {
			type: Boolean,
			required: true,
		},
	},
	computed: {
		...mapGetters({
			offer: 'offers/offer',
		}),
	},
}
</script>
